import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";

const statistics = {
  id: "group-statistics",
  title: "Statistics",
  admin: false,
  type: "group",
  children: [
    {
      id: "statistics-daily",
      title: "발전량(일보)",
      type: "item",
      url: "/statistics/daily",
      icon: InsertChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "statistics-weekly",
      title: "발전량(주보)",
      type: "item",
      url: "/statistics/weekly",
      icon: InsertChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "statistics-monthly",
      title: "발전량(월보)",
      type: "item",
      url: "/statistics/monthly",
      icon: InsertChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "statistics-yearly",
      title: "발전량(연보)",
      type: "item",
      url: "/statistics/yearly",
      icon: InsertChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "statistics-term",
      title: "발전량(기간)",
      type: "item",
      url: "/statistics/term",
      icon: InsertChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "statistics-times",
      title: "발전시간 관리",
      type: "item",
      url: "/statistics/times",
      icon: AutoGraphOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "statistics-predict",
      title: "발전량예측",
      type: "item",
      url: "/statistics/predict",
      icon: AddchartOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default statistics;
